<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>《未成年人保护措施》</h3>
        <p>Upupoo非常注重未成年人的保护。若您为未成年人，应在法定监护人监护、指导下阅读本协议，并在取得法定监护人的同意后使用upupoo的产品与/或服务</p>
        <p class="protocal-title-bold">1、相关的身份信息资料是否存在泄露的安全隐患？</p>
        <p> 我们坚持严加保护您和您孩子的个人信息及隐私，您输入资料的同时，系统均会执行同步加密，请您安心。</p>
        <p class="protocal-title-bold">2、upupoo帐号是否存在实名设置？</p>
        <p>目前所有新注册用户需要通过实名注册获得账号。</p>
        <p class="protocal-title-bold">3、充值之前是否需要实名设置？</p>
        <p>目前已按照相关要求进行了实名设置，账号需要完成实名设置后进行激活操作从而设置壁纸。</p>
        <p class="protocal-title-bold">4、如果孩子充值了，是否可以申诉退款？</p>
        <p>如您的孩子在使用过程中产生了未经您同意的与孩子年龄、智力不相适应的消费，请您发送邮件（upupoo@upupoo.com）提交相关资料进行申诉，我们会有专业的工作人员跟进您的问题。</p>

    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
